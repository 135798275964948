
import React, { useState } from 'react';
import { S3 } from 'aws-sdk';
import '../s3uploader/uploader.css'
import { Spin,Button} from 'antd'

const AWSS3Uploaderdynamic = ({ entityId, entityType, onImageUpload }) => {
  const [file, setFile] = useState(null);
  const [loading,setloading]=useState(false)
  const[uploaded,setuploaded]=useState(false)

  const AWS_ACCESS_KEY_ID = "AKIA2SG3VJ3SF2O5AVLZ";
  const AWS_SECRET_ACCESS_KEY = "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";
  const AWS_DEFAULT_REGION = "ap-south-1";

  // Set AWS credentials for the AWS SDK
  const awsCredentials = {
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
    region: AWS_DEFAULT_REGION,
  };

  const uploadToS3 = async (file) => {
    setloading(true)
    const s3 = new S3(awsCredentials);

    const params = {
        Bucket: "skrillnetworkimages",
        Key: `${file.name}`,
        Body: file,
        ContentType: file.type,
        ContentDisposition: "inline",
      };

    try {
      const result = await s3.upload(params).promise();
      const objectUrl = `https://${params.Bucket}.s3.${awsCredentials.region}.amazonaws.com/${params.Key}`;
     
      onImageUpload(objectUrl, entityId, entityType);
    } catch (error) {
      console.error(`Error uploading file to S3:`, error);
    }finally{
      setloading(false)
      setuploaded(true)
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (file) {
      uploadToS3(file);
    }
  };

  return (
    <div className="uploader">
      <input type="file" onChange={handleFileChange} />
      <Button type='primary' onClick={handleUpload}>Upload</Button>
      <Spin spinning={loading} className='spinner'/>
     {uploaded && <p className='message-colour'>File uploaded successfully!</p>}
    </div>
  );
};

export default AWSS3Uploaderdynamic;
