import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { addcategory, getcategory, deleteCategory } from "../../requests";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {notification }from 'antd'
import { Link } from "react-router-dom";

const Categories = () => {
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  }

  const [modal_delete, setmodal_delete] = useState(false);
  function tog_delete() {
    setmodal_delete(!modal_delete);
  }

  const [categoryEnteredbyUser, setCategoryEnteredbyUser] = useState("");
  const [Categories, setCategories] = useState([]);

  const addCategory = async () => {
    try {
      const token =localStorage.getItem('token')
      const resp = await addcategory({ name: categoryEnteredbyUser },token);
      // console.log(resp);
      notification.success({
        message: "Added",
        
      });
      setCategories((prevCategories) => [...prevCategories, resp]);
    } catch (err) {
      notification.error({
        message: "Failed",
        
      });
      console.log(err);
    }
  };

  const getCategory = async () => {
    try {
      const resp = await getcategory();
      // console.log(resp);
      setCategories(resp);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleAddCategory = (event) => {
    event.preventDefault();
    addCategory();
    setmodal_list(false);
  };

  const handleRemoveCategory = async (categoryId) => {
    try {
      const token =localStorage.getItem('token')
      const resp = await deleteCategory(categoryId,token);
      // console.log(resp);
      notification.success({
        message: "Removed",
        
      });
      getCategory();
    } catch (err) {
      notification.error({
        message: "Failed",
        
      });
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Skrill Network" breadcrumbItem="Categories" />
          <div>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div id="customerList">
                      <Row className="g-4 mb-3">
                        <Col className="col-sm-auto">
                          <div className="d-flex gap-1">
                            <Button
                              color="success"
                              className="add-btn"
                              onClick={() => tog_list()}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Add
                            </Button>
                          </div>
                        </Col>
                        {/* ... Other code for the search box */}
                      </Row>

                      <div className="table-responsive table-card mt-3 mb-1">
                        {/* Map through Categories state instead of using hardcoded data */}
                        <table className="table align-middle table-nowrap">
                          <thead className="table-light">
                            <tr>
                              <th className="sort" data-sort="category">
                                Category
                              </th>
                              <th className="sort" data-sort="actions">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {Categories.map((item, index) => (
                              <tr key={index}>
                                <td className="category">{item.name}</td>
                                <td className="actions">
                                  <div className="d-flex gap-2">
                                    <div className="remove">
                                      <button
                                        className="btn btn-sm btn-danger remove-item-btn"
                                        onClick={() =>
                                          handleRemoveCategory(
                                            item._id,
                                            item.name
                                          )
                                        }
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteRecordModal"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>

        <Modal
          isOpen={modal_list}
          toggle={() => {
            tog_list();
          }}
          centered
        >
          <ModalHeader
            className="bg-light p-3"
            id="exampleModalLabel"
            toggle={() => {
              tog_list();
            }}
          >
            Add Category
          </ModalHeader>
          <form className="tablelist-form">
            <ModalBody>
              <div className="mb-3">
                <label htmlFor="customername-field" className="form-label">
                  Category
                </label>
                <input
                  type="text"
                  id="customername-field"
                  className="form-control"
                  placeholder="Enter Category"
                  value={categoryEnteredbyUser}
                  onChange={(e) => setCategoryEnteredbyUser(e.target.value)}
                  required
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => setmodal_list(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  id="add-btn"
                  onClick={handleAddCategory}
                >
                  Add Category
                </button>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Categories;
