// EditPostModal.jsx
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Select, DatePicker ,notification,Spin} from "antd";
import dayjs from "dayjs";
import {
  getcategory,
  getcorporate,
  getcountry,
  gettags,
} from "../../../../requests";
import { S3 } from "aws-sdk";

const EditPostModal = ({ post, isOpen, toggleModal, handleSaveChanges }) => {
  const { Option } = Select;
  const [editorContent, setEditorContent] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCorporate, setselectedCorporate] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([]);

  const [Categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [corporates, setcorporates] = useState([]);

  const [title, setTitle] = useState("");
  const [metaTitle, setmetatitle] = useState("");
  const [metadescription, setmetadescription] = useState("");
  const [thumbnail, setthumbnail] = useState("");

  const [selectedDate, setSelectedDate] = useState(null);

  const [olddate, setolddate] = useState(null);

  const[loading,setLoading]=useState(false)

  const [loadingPost, setLoadingPost] = useState(true);


  console.log(post?.metatitle)
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorContent(data);
  };



  const AWS_ACCESS_KEY_ID = "AKIA2SG3VJ3SF2O5AVLZ";
  const AWS_SECRET_ACCESS_KEY = "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";
  const AWS_DEFAULT_REGION = "ap-south-1";
  const awsCredentials = {
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
    region: AWS_DEFAULT_REGION,
  };

  const formattedDate = selectedDate
    ? dayjs(selectedDate).format("MMM D YYYY")
    : "";

  useEffect(() => {
    setEditorContent(post?.editorContent || "");
    setSelectedCategories(post?.selectedCategories);
    setSelectedCountries(post?.selectedCountries);
    setSelectedTags(post?.selectedTags);
    setselectedCorporate(post?.selectedCorporate);
    setTitle(post?.postTitle);
    setmetadescription(post?.metadescription);
    setmetatitle(post?.metatitle);
    setthumbnail(post?.thumbnail);
    setolddate(post?.selectedDate);
  }, [post]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const getCategory = async () => {
    try {
      const resp = await getcategory();
      console.log(resp);
      setCategories(resp);
    } catch (err) {
      console.log(err);
    }
  };

  const getCountries = async () => {
    try {
      const resp = await getcountry();
      console.log(resp);
      setCountries(resp);
    } catch (err) {
      console.log(err);
    }
  };

  const getCorporate = async () => {
    try {
      const resp = await getcorporate();
      console.log(resp);
      setcorporates(resp);
    } catch (err) {
      console.log(err);
    }
  };

  const [tags, settags] = useState([]);
  const getTags = async () => {
    try {
      const resp = await gettags();
      console.log(resp);
      settags(resp);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategory();
    getTags();
    getCorporate();
    getCountries();
  }, []);

  const uploadToS3 = async (files) => {
    const s3 = new S3(awsCredentials);

    const uploadPromises = files.map(async (file) => {
      const params = {
        Bucket: "skrillnetworkimages",
        Key: `${file.name}`,
        Body: file,
        ContentType: file.type,
        ContentDisposition: "inline",
        // ACL: "public-read",
      };

      try {
        // Upload the file to S3
        const result = await s3.upload(params).promise();

        // Generate the object URL with the correct region
        const objectUrl = `https://${params.Bucket}.s3.${awsCredentials.region}.amazonaws.com/${params.Key}`;

        return objectUrl;
      } catch (error) {
        console.error(`Error uploading file ${file.name} to S3:`, error);
        return null; // Or handle the error as needed
      }
    });
    // Wait for all uploads to complete
    const objectUrls = await Promise.all(uploadPromises);
    return objectUrls.filter((url) => url !== null);
  };

  const handleSaveChangesInternal = async () => {
    try {
     
      const uploadedFiles = await uploadToS3(selectedFiles);
     
      const newupload = uploadedFiles.length > 0 ? uploadedFiles[0] : "";
  
      
      const updatedData = {
        thumbnail: newupload !== "" ? newupload : thumbnail,
        postTitle: title,
        metatitle: metaTitle,
        metadescription,
        editorContent,
        selectedCategories,
        selectedTags,
        selectedCorporate,
        selectedCountries,
        selectedDate: formattedDate || olddate,
      };
  
     
      handleSaveChanges(updatedData);
  
    
      toggleModal();
      // window.location.reload();
    } catch (error) {
     
      console.error("Error uploading files to S3:", error);
     
      notification.error({
        message: "Error",
        description: "There was an error uploading files to S3.",
      });
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleMetaTitleChange = (e) => {
    setmetatitle(e.target.value);
  };

  const handleMetaDescriptionChange = (e) => {
    setmetadescription(e.target.value);
  };

  function uploadAdapter(loader) {
    const awsCredentials = {
      accessKeyId: AWS_ACCESS_KEY_ID,
      secretAccessKey: AWS_SECRET_ACCESS_KEY,
      region: AWS_DEFAULT_REGION,
    };

    const s3 = new S3(awsCredentials);

    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const params = {
              Bucket: "skrillnetworkimages",
              Key: file.name,
              Body: file,
              ContentType: file.type,
              ContentDisposition: "inline",
            };

            s3.upload(params)
              .promise()
              .then((result) => {
                const objectUrl = `https://${params.Bucket}.s3.${awsCredentials.region}.amazonaws.com/${params.Key}`;

                // Resolve with the S3 URL for any additional processing
                resolve({ default: objectUrl });
              })
              .catch((err) => reject(err));
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader, editor);
    };
  }

  return (
    
    <Modal isOpen={isOpen} toggle={toggleModal} style={{ maxWidth: "80%" }}>
      <ModalHeader toggle={toggleModal}>Edit Post</ModalHeader>
      <ModalBody>
        <img style={{ width: "100%" }} src={post?.thumbnail} />
        <CardBody>
          <Dropzone
            onDrop={(acceptedFiles) => {
              handleAcceptedFiles(acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div style={{ textAlign: "center" }}>
                <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted mdi mdi-cloud-upload-outline"></i>
                  </div>
                  <h4>Drop files here to upload</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews mt-3" id="file-previews">
            {selectedFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={f.name}
                          src={f.preview}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })}
          </div>
        </CardBody>
        {/* Add your form fields with post data */}
        <div style={{ marginTop: "20px" }}>
          <label>Title:</label>
          <Input
            type="text"
            defaultValue={post?.postTitle}
            onChange={handleTitleChange}
          />
        </div>

        <div style={{ marginTop: "20px" }}>
          <label>Meta Title:</label>
          <Input
            type="text"
            defaultValue={post?.metatitle}
            onChange={handleMetaTitleChange}
          />
        </div>

        <div style={{ marginTop: "20px" }}>
          <label>Meta Description:</label>
          <Input
            type="text"
            defaultValue={post?.metadescription}
            onChange={handleMetaDescriptionChange}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <div>Current Date : {post?.selectedDate}</div>
          {/* <Antform.Item label="Select Date"> */}
          <DatePicker
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
          />
          {/* </Antform.Item> */}
        </div>
        <div style={{ marginTop: "20px" }}>
          <label>Category:</label>
          <Select
            mode="multiple"
            placeholder="Select Country"
            value={selectedCategories}
            onChange={(values) => setSelectedCategories(values)}
            style={{ width: "100%" }}
          >
            {Categories?.map((category) => (
              <Option key={category._id} value={category.name}>
                {category.name}
              </Option>
            ))}
          </Select>
        </div>

        <div style={{ marginTop: "20px" }}>
          <label>Country:</label>

          <Select
            mode="multiple"
            placeholder="Select Country"
            value={selectedCountries}
            onChange={(values) => setSelectedCountries(values)}
            style={{ width: "100%" }}
          >
            {countries.map((category) => (
              <Option key={category._id} value={category.name}>
                {category.name}
              </Option>
            ))}
          </Select>
        </div>

        <div style={{ marginTop: "20px" }}>
          <label>Tags:</label>
          <Select
            mode="multiple"
            placeholder="Select Tags"
            value={selectedTags}
            onChange={(values) => setSelectedTags(values)}
            style={{ width: "100%" }}
          >
            {tags.map((tag) => (
              <Option key={tag._id} value={tag.name}>
                {tag.name}
              </Option>
            ))}
          </Select>
        </div>

        <div style={{ marginTop: "20px" }}>
          <label>Corporate:</label>
          <Select
            mode="multiple"
            placeholder="Select Corporate"
            value={selectedCorporate}
            onChange={(values) => setselectedCorporate(values)}
            style={{ width: "100%" }}
          >
            {corporates.map((category) => (
              <Option key={category._id} value={category.name}>
                {category.name}
              </Option>
            ))}
          </Select>
        </div>

        <div style={{ marginTop: "20px" }}>
          <label>Content:</label>
          {/* CKEditor component for editing post content */}
          <CKEditor
          config={{
            extraPlugins: [uploadPlugin],
          }}
            editor={ClassicEditor}
            data={editorContent}
            onChange={handleEditorChange}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveChangesInternal}>
          Save Changes
        </Button>
        <Button color="secondary" onClick={toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
   
  );
};

export default EditPostModal;
