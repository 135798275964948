import React, { useState } from 'react';
import { S3 } from 'aws-sdk';
import{ Spin,Button} from 'antd'
import './uploader.css'

const AWSS3Uploader = ({ onUploadComplete }) => {
  const [file, setFile] = useState(null);
  const [loading,setloading]=useState(false)
  const[uploaded,setuploaded]=useState(false)

  const AWS_ACCESS_KEY_ID = "AKIA2SG3VJ3SF2O5AVLZ";
  const AWS_SECRET_ACCESS_KEY = "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";
  const AWS_DEFAULT_REGION = "ap-south-1";

  // Set AWS credentials for the AWS SDK
  const awsCredentials = {
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
    region: AWS_DEFAULT_REGION,
  };

  const uploadToS3 = async (file) => {
    const s3 = new S3(awsCredentials);

    const params = {
      Bucket: "skrillnetworkimages",
      Key: `${file.name}`,
      Body: file,
      ContentType: file.type,
      ContentDisposition: "inline",
    };

    try {
      setloading(true)
      // Upload the file to S3
      const result = await s3.upload(params).promise();

      // Generate the object URL with the correct region
      const objectUrl = `https://${params.Bucket}.s3.${awsCredentials.region}.amazonaws.com/${params.Key}`;

      // Call the callback function with the uploaded URL
      onUploadComplete(objectUrl);
    } catch (error) {
      console.error(`Error uploading file ${file.name} to S3:`, error);
      // Or handle the error as needed
    }finally{
      setloading(false)
      setuploaded(true)
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (file) {
      uploadToS3(file);
    }
  };

  return (
    <div className="uploader">
      <input type="file" onChange={handleFileChange} />
      <Button type='primary' onClick={handleUpload}>Upload</Button>
     <Spin spinning={loading} className='spinner'/>
     {uploaded && <p className='message-colour'>File uploaded successfully!</p>}
    </div>
  );
};

export default AWSS3Uploader;
