import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { useNavigate } from "react-router-dom";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN,LOGIN_SUCCESS } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { adminlogin } from "../../../requests";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";

// const navigate = useNavigate(); 
const fireBaseBackend = getFirebaseBackend();
function* loginUser({ payload: { user,history } }) {
  
  try {
    const { username, password } = user;
    const response = yield call(adminlogin, { username, password });
    
    // Assuming you have a successful login
    yield put(loginSuccess(response));
    
    // Save the token to localStorage or sessionStorage
    localStorage.setItem("token", response.token);
    
    // Dispatch a success action
    yield put({ type: LOGIN_SUCCESS });
    history("/dashboard");
  } catch (error) {
    // Handle other errors
    yield put(apiError(error, error.status));
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(LOGOUT_USER, response));
    } else {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, data, type);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
