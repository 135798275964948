import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { addcorporate, getcorporate, deleteCorporate } from "../../requests";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import { S3 } from "aws-sdk";
import {notification }from 'antd'

const Corporate = () => {
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  }

  const AWS_ACCESS_KEY_ID = "AKIA2SG3VJ3SF2O5AVLZ";
  const AWS_SECRET_ACCESS_KEY = "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";
  const AWS_DEFAULT_REGION = "ap-south-1";

  // Set AWS credentials for the AWS SDK
  const awsCredentials = {
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
    region: AWS_DEFAULT_REGION,
  };

  const uploadToS3 = async (file) => {
    const s3 = new S3(awsCredentials);
  
    const params = {
      Bucket: "skrillnetworkimages",
      Key: `${file.name}`,
      Body: file,
      ContentType: file.type,
      ContentDisposition: "inline",
    };
  
    try {
      // Upload the file to S3
      const result = await s3.upload(params).promise();
  
      // Generate the object URL with the correct region
      const objectUrl = `https://${params.Bucket}.s3.${awsCredentials.region}.amazonaws.com/${params.Key}`;
  
      return objectUrl;
    } catch (error) {
      console.error(`Error uploading file ${file.name} to S3:`, error);
      return null; // Or handle the error as needed
    }
  };

 


  const [modal_delete, setmodal_delete] = useState(false);
  function tog_delete() {
    setmodal_delete(!modal_delete);
  }

  const [categoryEnteredbyUser, setCategoryEnteredbyUser] = useState("");
  const [ticker, setTicker] = useState("");
const [logoImage, setLogoImage] = useState(null); 
const [companyType, setCompanyType] = useState("");
const [natureOfBusiness, setNatureOfBusiness] = useState("");
  const [Categories, setCategories] = useState([]);

  const addCategory = async () => {
    const uploadedFile = await uploadToS3(logoImage);
    const url = uploadedFile ? uploadedFile : "";
  
    const payload = {
      name: categoryEnteredbyUser,
      ticker: ticker,
      logoImage: url,
      companyType: companyType,
      natureOfBusiness: natureOfBusiness,
    };
  
    try {
      const token=localStorage.getItem('token')
      const resp = await addcorporate(payload,token);
      // console.log(resp);
      // console.log(payload);
      notification.success({
        message: "Added",
        
      });
      setCategories((prevCategories) => [...prevCategories, resp]);
    } catch (err) {
      notification.error({
        message: "Failed",
        
      });
      console.log(err);
    }
  };
  

  const getCategory = async () => {
    try {
      const resp = await getcorporate();
      console.log(resp);
      setCategories(resp);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleAddCategory = (event) => {
    event.preventDefault();
    addCategory();
    setmodal_list(false);
  };

  const handleRemoveCategory = async (Id) => {
    try {
      const resp = await deleteCorporate(Id);
      console.log(resp);
      notification.success({
        message: "Removed",
        
      });
      getCategory();
    } catch (err) {
      notification.error({
        message: "Failed",
        
      });
      console.log(err);
    }
  };

  const handleLogoImageChange = (e) => {
    // Handle logo image change
    const file = e.target.files[0];
    setLogoImage(file);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Skrill Network" breadcrumbItem="Corporate" />
          <div>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div id="customerList">
                      <Row className="g-4 mb-3">
                        <Col className="col-sm-auto">
                          <div className="d-flex gap-1">
                            <Button
                              color="success"
                              className="add-btn"
                              onClick={() => tog_list()}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Add
                            </Button>
                          </div>
                        </Col>
                        {/* ... Other code for the search box */}
                      </Row>

                      <div className="table-responsive table-card mt-3 mb-1">
                        {/* Map through Categories state instead of using hardcoded data */}
                        <table className="table align-middle table-nowrap">
                          <thead className="table-light">
                            <tr>
                            <th className="sort" data-sort="category">
                                Logo
                              </th>
                              <th className="sort" data-sort="category">
                                Corporate
                              </th>
                              
                              <th className="sort" data-sort="category">
                                Nature of Business
                              </th>
                              <th className="sort" data-sort="category">
                                Type
                              </th>
                              <th className="sort" data-sort="actions">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {Categories.map((item, index) => (
                              <tr key={index}>
                                <td className="category">{item.name}</td>
                                <td className="category"><img height={30} src={item.logoImage} alt={item.name}/></td>
                                <td className="category">{item.natureOfBusiness}</td>
                                <td className="category">{item.companyType}</td>
                                <td className="actions">
                                  <div className="d-flex gap-2">
                                    <div className="remove">
                                      <button
                                        className="btn btn-sm btn-danger remove-item-btn"
                                        onClick={() =>
                                          handleRemoveCategory(
                                            item._id,
                                            // item.name
                                          )
                                        }
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteRecordModal"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>

        <Modal
          isOpen={modal_list}
          toggle={() => {
            tog_list();
          }}
          centered
        >
          <ModalHeader
            className="bg-light p-3"
            id="exampleModalLabel"
            toggle={() => {
              tog_list();
            }}
          >
            Add Corporate
          </ModalHeader>
          <form className="tablelist-form" onSubmit={handleAddCategory}>
          <ModalBody>
            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">
                Corporate
              </label>
              <input
                type="text"
                id="customername-field"
                className="form-control"
                placeholder="Enter Corporate"
                value={categoryEnteredbyUser}
                onChange={(e) => setCategoryEnteredbyUser(e.target.value)}
                required
              />
            </div>
            {/* Additional Fields */}
            <div className="mb-3">
              <label htmlFor="ticker-field" className="form-label">
                Ticker
              </label>
              <input
                type="text"
                id="ticker-field"
                className="form-control"
                placeholder="Enter Ticker"
                value={ticker}
                onChange={(e) => setTicker(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="logo-image-field" className="form-label">
                Logo Image
              </label>
              <input
                type="file"
                id="logo-image-field"
                className="form-control"
                onChange={handleLogoImageChange}
              />
            </div>
            {/* Add dropdown for Company Type */}
            <div className="mb-3">
              <label htmlFor="company-type-field" className="form-label">
                Company Type
              </label>
              <select
                id="company-type-field"
                className="form-select"
                value={companyType}
                onChange={(e) => setCompanyType(e.target.value)}
              >
                <option value="">Select Company Type</option>
                <option value="company">Company</option>
                <option value="startup">Startup</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="nature-of-business-field" className="form-label">
                Nature of Business
              </label>
              <input
                type="text"
                id="nature-of-business-field"
                className="form-control"
                placeholder="Enter Nature of Business"
                value={natureOfBusiness}
                onChange={(e) => setNatureOfBusiness(e.target.value)}
              />
            </div>
          </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => setmodal_list(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  id="add-btn"
                  // onClick={handleAddCategory}
                >
                  Add Corporate
                </button>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Corporate;
