import { Container,Row,Col } from "reactstrap";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { companies } from "../../requests";
import CompanyCard from "../../components/Common/new/companycard";

const Startups = () => {

  const[allcompanies,setcompanies]=useState([])
  const getCompanies = async () => {
    try {
      const resp = await companies();
      // console.log(resp);
      setcompanies(resp)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Skrill Network" breadcrumbItem="All Companies" />
          <div>
          <Row>
            {allcompanies?.map((data) => (
              <Col sm={6} md={4} lg={3} key={data._id}>
                <CompanyCard
                  company={data}
                  // handleEditClick={handleEditClick}
                  // handleDeleteClick={handleDeleteClick}
                />
              </Col>
            ))}
          </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Startups;
