import React, { useEffect } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useState } from "react";
import { addtags, gettags ,deletetag} from "../../requests";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import {notification }from 'antd'
import { Link } from "react-router-dom";

const Tags = () => {
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  }

  const [modal_delete, setmodal_delete] = useState(false);
  function tog_delete() {
    setmodal_delete(!modal_delete);
  }

  const [tagsEnteredByuser, setTagsenteredbytheuser] = useState("");
  const [tags, settags] = useState([]);
  const getTags = async () => {
    try {
      const resp = await gettags();
      console.log(resp);
      settags(resp);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  const addTags = async () => {
    try {
      const resp = await addtags({ name: tagsEnteredByuser });
      console.log(resp);
      notification.success({
        message: "Added",
        
      });
      getTags()
    } catch (err) {
      notification.error({
        message: "Failed",
        
      });
      console.log(err);
    }
  };

  const handleAddTag = (event) => {
    event.preventDefault();
    addTags();
    setmodal_list(false);
  };

  const handleRemoveTag = async(id, name) => {
    // console.log(id, name);
    try{
      const resp = await deletetag(id);
      console.log(resp);
      notification.success({
        message: "Removed",
        
      });
      getTags();
    }

    catch(err){
      notification.error({
        message: "Failed",
        
      });
      console.log(err)
    }

  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Skrill Network" breadcrumbItem="Tags" />
          <div>
            <Row>
              <Col lg={12}>
                <Card>
                  {/* <CardHeader>
                  <h4 className="card-title mb-0">Add, Edit & Remove</h4>
                </CardHeader> */}
                  <CardBody>
                    <div id="customerList">
                      <Row className="g-4 mb-3">
                        <Col className="col-sm-auto">
                          <div className="d-flex gap-1">
                            <Button
                              color="success"
                              className="add-btn"
                              onClick={() => tog_list()}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Add
                            </Button>
                            {/* <Button
                              color="soft-danger"
                              // onClick="deleteMultiple()"
                            >
                              <i className="ri-delete-bin-2-line"></i>
                            </Button> */}
                          </div>
                        </Col>
                        <Col className="col-sm">
                          <div className="d-flex justify-content-sm-end">
                            {/* <div className="search-box ms-2">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Search..."
                              />
                              <i className="ri-search-line search-icon"></i>
                            </div> */}
                          </div>
                        </Col>
                      </Row>

                      <div className="table-responsive table-card mt-3 mb-1">
                        <table className="table align-middle table-nowrap">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "50px" }}>
                                {/* <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                  />
                                </div> */}
                              </th>
                              <th className="sort" data-sort="tag">
                                Tags
                              </th>
                              <th className="sort" data-sort="actions">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {tags.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {/* <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="option"
                />
              </div> */}
                                </td>
                                <td className="tag">{item.name}</td>
                                <td className="actions">
                                  <div className="d-flex gap-2">
                                    {/* ... Other buttons */}
                                    <div className="remove">
                                      <button
                                        className="btn btn-sm btn-danger remove-item-btn"
                                        onClick={() =>
                                          handleRemoveTag(item._id, item.name)
                                        }
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteRecordModal"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>

        <Modal
          isOpen={modal_list}
          toggle={() => {
            tog_list();
          }}
          centered
        >
          <ModalHeader
            className="bg-light p-3"
            id="exampleModalLabel"
            toggle={() => {
              tog_list();
            }}
          >
            {" "}
            Add Tag{" "}
          </ModalHeader>
          <form className="tablelist-form">
            <ModalBody>
              <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                <label htmlFor="id-field" className="form-label">
                  ID
                </label>
                <input
                  type="text"
                  id="id-field"
                  className="form-control"
                  placeholder="ID"
                  readOnly
                />
              </div>

              <div className="mb-3">
                <label htmlFor="customername-field" className="form-label">
                  Tags
                </label>
                <input
                  type="text"
                  id="customername-field"
                  className="form-control"
                  placeholder="Enter Tag"
                  value={tagsEnteredByuser}
                  onChange={(e) => setTagsenteredbytheuser(e.target.value)}
                  required
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => setmodal_list(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  id="add-btn"
                  onClick={handleAddTag}
                >
                  Add Tag
                </button>
                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Tags;
