import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Form as Antform, Input, Select, Space } from "antd";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { notification,Spin } from "antd";
import {
  gettags,
  getcategory,
  addPodcast,
  podcasts,
  deletePodcast,
} from "../../requests";
import AWSS3Uploader from "../../components/Common/new/s3uploader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Podcasts = () => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [videosd, setVideos] = useState([]);
  const[loading,setloading]=useState(false)
  const [thumbnail, setThumbnail] = useState("");
  const [link, setLink] = useState("");

  const [description, setDescription] = useState("");

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [videosData, setVideosData] = useState([]);

  const [Categories, setCategories] = useState([]);
  const [tags, settags] = useState([]);
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const { Option } = Select;
  // Mock data for Categories and tags

  const addVideoData = async () => {
    setloading(true)
    try {
      const newVideo = {
        description: description,
        thumbnail: thumbnail,
        link: link,
        categories: selectedCategories,
        tags: selectedTags,
        title: title,
        company: company,
        designation: designation,
      };
      // console.log(newVideo);
      const resp = await addPodcast(newVideo);
      // console.log(resp)
      notification.success({
        message: "Added",
      });
      setDescription("");
      setThumbnail("");
      setLink("");
      setTitle("");
      setCompany("");
      setDesignation("");
      setSelectedCategories([]);
      setSelectedTags([]);
      toggleModal();
    } catch (err) {
      notification.error({
        message: "something went wrong",
      });
      console.log(err);
    } finally {
      getData();
      setloading(false)
    }
  };

  const removeVideo = async (id) => {
    setloading(true)
    try {
      const res = await deletePodcast(id);
      notification.success({
        message: "Removed",
      });
    } catch (err) {
      notification.error({
        message: "something went wrong",
      });
      console.log(err);
    } finally {
      getData();
      setloading(false)
    }
  };

  const getTags = async () => {
    setloading(true)
    try {
      const resp = await gettags();
      console.log(resp);
      settags(resp);
    } catch (err) {
      console.log(err);
    }finally{
      setloading(false)
    }
  };

  const getCategory = async () => {
    try {
      const resp = await getcategory();
      console.log(resp);
      setCategories(resp);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategory();
    getTags();
  }, []);

  const uploadThumbnail = (uploadedUrl) => {
    setThumbnail(uploadedUrl);
  };

  const getData = async () => {
    try {
      const res = await podcasts();
      setVideosData(res);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <Spin fullscreen spinning={loading}/>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Skrill Network" breadcrumbItem="Podcasts" />
          <div>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div id="videoList">
                      <Row className="g-4 mb-3">
                        <Col className="col-sm-auto">
                          <div className="d-flex gap-2">
                            <Button
                              color="success"
                              className="add-btn"
                              onClick={toggleModal}
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Add Podcast
                            </Button>
                          </div>
                        </Col>
                      </Row>

                      <div className="table-responsive table-card mt-3 mb-1">
                        <table className="table align-middle table-nowrap">
                          <thead className="table-light">
                            <tr>
                              <th>Thumbnail</th>
                              <th>Title</th>
                              <th>Company</th>
                              <th>Designation</th>
                              <th>Link</th>
                              <th>Categories</th>
                              <th>Tags</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {videosData.map((video, index) => (
                              <tr key={index}>
                                <td>
                                  <img
                                    src={video.thumbnail}
                                    alt={`Thumbnail ${index}`}
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                </td>
                                <td>{video?.title}</td>
                                <td>{video?.company}</td>
                                <td>{video?.designation}</td>
                                <td>{video?.link}</td>
                                <td>{video?.categories.join(", ")}</td>
                                <td>{video?.tags.join(", ")}</td>
                                <td>
                                  <Button
                                    color="danger"
                                    size="sm"
                                    onClick={() => removeVideo(video._id)}
                                  >
                                    Remove
                                  </Button>{" "}
                                  {/* Add edit functionality if needed */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>

        <Modal isOpen={modal} toggle={toggleModal} centered>
          <ModalHeader className="bg-light p-3" toggle={toggleModal}>
            Add Video
          </ModalHeader>
          <form className="tablelist-form">
            <ModalBody>
              <div className="mb-3">
                <label htmlFor="thumbnail-field" className="form-label">
                  Thumbnail Image
                </label>
                <AWSS3Uploader onUploadComplete={uploadThumbnail} />
              </div>

              <div className="mb-3">
                <label htmlFor="link-field" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  id="link-field"
                  className="form-control"
                  placeholder="Enter Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
             
              <div className="mb-3">
                <label htmlFor="link-field" className="form-label">
                  Company
                </label>
                <input
                  type="text"
                  id="link-field"
                  className="form-control"
                  placeholder="Enter company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="link-field" className="form-label">
                  Designation
                </label>
                <input
                  type="text"
                  id="link-field"
                  className="form-control"
                  placeholder="Enter Designation"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="link-field" className="form-label">
                  RSS Link
                </label>
                <input
                  type="text"
                  id="link-field"
                  className="form-control"
                  placeholder="Enter RSS Link"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="link-field" className="form-label">
                  Description
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={(event, editor) => setDescription(editor.getData())}
                />
              </div>
              <Antform>
                <Row>
                  {/* Categories Select */}
                  <Col md={4}>
                    <Select
                      mode="multiple"
                      placeholder="Select Categories"
                      value={selectedCategories}
                      onChange={(values) => {
                        console.log(values); // Log selected values
                        setSelectedCategories(values);
                      }}
                      style={{ width: "100%" }}
                    >
                      {Categories.map((category) => (
                        <Option key={category._id} value={category.name}>
                          {category.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>

                  {/* Tags Select */}
                  <Col md={4}>
                    <Select
                      mode="multiple"
                      placeholder="Select Tags"
                      value={selectedTags}
                      onChange={(values) => setSelectedTags(values)}
                      style={{ width: "100%" }}
                    >
                      {tags.map((tag) => (
                        <Option key={tag._id} value={tag.name}>
                          {tag.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Antform>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <Button type="button" color="light" onClick={toggleModal}>
                  Close
                </Button>
                <Button type="button" color="success" onClick={addVideoData}>
                  Add Podcast
                </Button>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Podcasts;
