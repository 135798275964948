import { Container } from "reactstrap";
import { useState, useEffect } from "react";
import React from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  Input,
  Button,
  Select,
  Space,
  notification,
} from "antd";

import { S3 } from "aws-sdk";
import { deepDive, getcorporate, getcategory, gettags } from "../../requests";
import AWSS3Uploader from "../../components/Common/new/s3uploader";
import AWSS3Uploaderdynamic from "../../components/Common/new/s3uploaderDynamic";

const Companies = () => {
  const { Option } = Select;

  const [textFields, setTextFields] = useState([""]);

  const [videoLink, setVideoLink] = useState("");
  const [selectedCompanyType, setSelectedCompanyType] = useState("");
  const [aboutCompanyDescription, setAboutCompanyDescription] = useState(
    "<p>Enter your text here</p>"
  );
  const [AnnouncementLink, setAnnouncementlink] = useState("");
  const [corporateCompanies, setCompanies] = useState([]);
  const [metaImage, setmetaImage] = useState(null);

  const [metaTitle, setmetatitle] = useState("");
  const [metaDescription, setmetadescription] = useState("");

  const [projects, setProjects] = useState([
    { id: 1, logoImage: null, description: "", learnMoreLink: "" },
  ]);

  const [products, setProducts] = useState([
    { id: 1, logoImage: null, description: "", learnMoreLink: "" },
  ]);

  const [milestones, setMilestones] = useState([
    { id: 1, heading: "", quarters: "", milestone: "" },
  ]);

  const [people, setPeople] = useState([
    { id: 1, name: "", company: "", image: null, description: "" },
  ]);

  const [investors, setInvestors] = useState([
    {
      id: 1,
      heading: "",
      monthYear: "",
      raised: "",
      offerPrice: "",
      notes: "",
      description: "",
    },
  ]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, settags] = useState([]);
  const [Categories, setCategories] = useState([]);

  const getCategory = async () => {
    try {
      const resp = await getcorporate();
      // console.log(resp);
      setCompanies(resp);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  //Highlights functions starts here
  const handleAddMore = () => {
    setTextFields([...textFields, ""]);
  };

  const handleTextFieldChange = (index, value) => {
    const updatedTextFields = [...textFields];
    updatedTextFields[index] = value;
    setTextFields(updatedTextFields);
  };

  const handleRemoveField = (indexToRemove) => {
    const updatedTextFields = textFields.filter(
      (_, index) => index !== indexToRemove
    );
    setTextFields(updatedTextFields);
  };

  //Highlights functions ends here

  //Project functions starts here
  const handleDescriptionChange = (data, projectId) => {
    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === projectId ? { ...project, description: data } : project
      )
    );
  };

  const handleLearnMoreLinkChange = (e, projectId) => {
    const value = e.target.value;

    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === projectId
          ? { ...project, learnMoreLink: value }
          : project
      )
    );
  };

  const handleAddMoreProjects = () => {
    const newProject = {
      id: projects.length + 1,
      logoImage: null,
      description: "",
      learnMoreLink: "",
    };
    setProjects([...projects, newProject]);
  };

  const handleRemove = (projectId) => {
    setProjects((prevProjects) =>
      prevProjects.filter((project) => project.id !== projectId)
    );
  };

  //Projects functions ends here

  //Milestone functions starts here
  const handleHeadingChange = (e, milestoneId) => {
    const value = e.target.value;

    setMilestones((prevMilestones) =>
      prevMilestones.map((milestone) =>
        milestone.id === milestoneId
          ? { ...milestone, heading: value }
          : milestone
      )
    );
  };

  const handleQuartersChange = (e, milestoneId) => {
    const value = e.target.value;

    setMilestones((prevMilestones) =>
      prevMilestones.map((milestone) =>
        milestone.id === milestoneId
          ? { ...milestone, quarters: value }
          : milestone
      )
    );
  };

  const handleMilestoneChange = (e, milestoneId) => {
    const value = e.target.value;

    setMilestones((prevMilestones) =>
      prevMilestones.map((milestone) =>
        milestone.id === milestoneId
          ? { ...milestone, milestone: value }
          : milestone
      )
    );
  };

  const handleAddMoremile = () => {
    const newMilestone = {
      id: milestones.length + 1,
      heading: "",
      quarters: "",
      milestone: "",
    };
    setMilestones([...milestones, newMilestone]);
  };

  const handleRemovemile = (milestoneId) => {
    setMilestones((prevMilestones) =>
      prevMilestones.filter((milestone) => milestone.id !== milestoneId)
    );
  };

  //Milestones functions ends here

  //People functions starts here
  const handleNameChange = (e, personId) => {
    const value = e.target.value;

    setPeople((prevPeople) =>
      prevPeople.map((person) =>
        person.id === personId ? { ...person, name: value } : person
      )
    );
  };

  const handleCompanyChange = (e, personId) => {
    const value = e.target.value;

    setPeople((prevPeople) =>
      prevPeople.map((person) =>
        person.id === personId ? { ...person, company: value } : person
      )
    );
  };

  const handleDescriptionChangepeople = (data, personId) => {
    setPeople((prevPeople) =>
      prevPeople.map((person) =>
        person.id === personId ? { ...person, description: data } : person
      )
    );
  };

  const handleAddMorepeople = () => {
    const newPerson = {
      id: people.length + 1,
      name: "",
      company: "",
      image: null,
      description: "",
    };

    setPeople([...people, newPerson]);
  };

  const handleRemovepeople = (personId) => {
    setPeople((prevPeople) =>
      prevPeople.filter((person) => person.id !== personId)
    );
  };

  //People functions ends here

  //Investors function starts here
  const handleHeadingChangeInv = (e, investorId) => {
    const value = e.target.value;

    setInvestors((prevInvestors) =>
      prevInvestors.map((investor) =>
        investor.id === investorId ? { ...investor, heading: value } : investor
      )
    );
  };

  const handleMonthYearChange = (e, investorId) => {
    const value = e.target.value;

    setInvestors((prevInvestors) =>
      prevInvestors.map((investor) =>
        investor.id === investorId
          ? { ...investor, monthYear: value }
          : investor
      )
    );
  };

  const handleRaisedChange = (e, investorId) => {
    const value = e.target.value;

    setInvestors((prevInvestors) =>
      prevInvestors.map((investor) =>
        investor.id === investorId ? { ...investor, raised: value } : investor
      )
    );
  };

  const handleOfferPriceChange = (e, investorId) => {
    const value = e.target.value;

    setInvestors((prevInvestors) =>
      prevInvestors.map((investor) =>
        investor.id === investorId
          ? { ...investor, offerPrice: value }
          : investor
      )
    );
  };

  const handleNotesChange = (e, investorId) => {
    const value = e.target.value;

    setInvestors((prevInvestors) =>
      prevInvestors.map((investor) =>
        investor.id === investorId ? { ...investor, notes: value } : investor
      )
    );
  };

  const handleDescriptionChangeInv = (data, investorId) => {
    setInvestors((prevInvestors) =>
      prevInvestors.map((investor) =>
        investor.id === investorId
          ? { ...investor, description: data }
          : investor
      )
    );
  };

  const handleAddMoreInv = () => {
    const newInvestor = {
      id: investors.length + 1,
      heading: "",
      monthYear: "",
      raised: "",
      offerPrice: "",
      notes: "",
      description: "",
    };

    setInvestors([...investors, newInvestor]);
  };

  const handleRemoveInv = (investorId) => {
    setInvestors((prevInvestors) =>
      prevInvestors.filter((investor) => investor.id !== investorId)
    );
  };

  //investors functions ends here

  const handleSubmit = async () => {
    // Extracted data from the form
    const aboutCompanyData = {
      companyName: selectedCompanyType,
      metaImage: metaImage,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      category: selectedCategories,
      tags: selectedTags,
      description: aboutCompanyDescription,
      highlights: textFields.map((value, index) => ({
        text: value,
      })),
      videoLink: videoLink,
    };

    const projectsData = projects.map((project) => ({
      logoImage: project.logoImage,
      description: project.description,
      learnMoreLink: project.learnMoreLink,
    }));

    const productsData = products.map((product) => ({
      logoImage: product.logoImage,
      description: product.description,
      learnMoreLink: product.learnMoreLink,
    }));

    const milestonesData = milestones.map((milestone) => ({
      heading: milestone.heading,
      quarters: milestone.quarters,
      milestone: milestone.milestone,
    }));

    const peopleData = people.map((person) => ({
      name: person.name,
      company: person.company,
      image: person.image,
      description: person.description,
    }));

    const investorsData = investors.map((investor) => ({
      heading: investor.heading,
      monthYear: investor.monthYear,
      raised: investor.raised,
      offerPrice: investor.offerPrice,
      notes: investor.notes,
      description: investor.description,
    }));

    const apiPayload = {
      company: aboutCompanyData,
      projects: projectsData,
      products: productsData,
      milestones: milestonesData,
      people: peopleData,
      investors: investorsData,
      announcementLink: AnnouncementLink,
    };

    console.log("API Payload:", apiPayload);
    try {
      const token = localStorage.getItem("token");
      const resp = await deepDive(apiPayload, token);
      notification.success({
        message: "Submission Successful",
        description: "Your data has been successfully submitted.",
      });
      // console.log(resp);
    } catch (err) {
      notification.error({
        message: "Submission Failed",
        description:
          "There was an error submitting your data. Please try again.",
      });
      console.log(err);
    }
  };

  const handleCompanyChangee = (value) => {
    const selectedCompany = corporateCompanies.find(
      (company) => company.name === value
    );
    setSelectedCompanyType(selectedCompany ? selectedCompany.name : null);
  };

  //Products Functions starts here
  const handleProductDescriptionChange = (data, productId) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, description: data } : product
      )
    );
  };

  const handleProductLearnMoreLinkChange = (e, productId) => {
    const value = e.target.value;

    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId
          ? { ...product, learnMoreLink: value }
          : product
      )
    );
  };

  const handleAddMoreProducts = () => {
    const newProduct = {
      id: products.length + 1,
      logoImage: null,
      description: "",
      learnMoreLink: "",
    };
    setProducts([...products, newProduct]);
  };

  const handleRemoveProduct = (productId) => {
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product.id !== productId)
    );
  };

  //Products Functions ends here

  const handleCategoryChange = (selectedValues) => {
    setSelectedCategories(selectedValues);
  };

  const handleTagChange = (selectedValues) => {
    setSelectedTags(selectedValues);
  };

  const getCategorydeep = async () => {
    try {
      const resp = await getcategory();
      // console.log(resp);
      setCategories(resp);
    } catch (err) {
      console.log(err);
    }
  };

  const getTags = async () => {
    try {
      const resp = await gettags();
      console.log(resp);
      settags(resp);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCategorydeep();
    getTags();
  }, []);

  const AWS_ACCESS_KEY_ID = "AKIA2SG3VJ3SF2O5AVLZ";
  const AWS_SECRET_ACCESS_KEY = "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";
  const AWS_DEFAULT_REGION = "ap-south-1";

  function uploadAdapter(loader) {
    const awsCredentials = {
      accessKeyId: AWS_ACCESS_KEY_ID,
      secretAccessKey: AWS_SECRET_ACCESS_KEY,
      region: AWS_DEFAULT_REGION,
    };

    const s3 = new S3(awsCredentials);

    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const params = {
              Bucket: "skrillnetworkimages",
              Key: file.name,
              Body: file,
              ContentType: file.type,
              ContentDisposition: "inline",
            };

            s3.upload(params)
              .promise()
              .then((result) => {
                const objectUrl = `https://${params.Bucket}.s3.${awsCredentials.region}.amazonaws.com/${params.Key}`;

                // Resolve with the S3 URL for any additional processing
                resolve({ default: objectUrl });
              })
              .catch((err) => reject(err));
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader, editor);
    };
  }

  const handleUploadComplete = (uploadedUrl) => {
    // Do something with the uploaded URL, e.g., save it in the state
    console.log("Uploaded URL:", uploadedUrl);
    setmetaImage(uploadedUrl);
  };

  const handleImageUpload = (uploadedUrl, entityId, entityType) => {
    if (entityType === "person") {
      setPeople((prevPeople) =>
        prevPeople.map((person) =>
          person.id === entityId ? { ...person, image: uploadedUrl } : person
        )
      );
    } else if (entityType === "project") {
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project.id === entityId
            ? { ...project, logoImage: uploadedUrl }
            : project
        )
      );
    } else if (entityType === "product") {
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === entityId
            ? { ...product, logoImage: uploadedUrl }
            : product
        )
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Skrill Network" breadcrumbItem="Companies" />
          <div
            style={{
              marginTop: "20px",
              background: "#ded4d4",
              padding: "10px",
            }}
          >
            {/* <h2>About Company</h2> */}

            <Space direction="vertical" size={16}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h5>Select Company/Startup</h5>
                  <Select
                    placeholder="Select option"
                    style={{ width: 200 }}
                    value={selectedCompanyType}
                    onChange={handleCompanyChangee}
                  >
                    {corporateCompanies.map((company) => (
                      <Select.Option key={company.name} value={company.name}>
                        {company.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div>
                  <h5>Select Category</h5>
                  <Select
                    mode="multiple"
                    placeholder="Select category"
                    style={{ width: 200 }}
                    value={selectedCategories}
                    onChange={handleCategoryChange}
                  >
                    {Categories.map((category) => (
                      <Select.Option key={category.name} value={category.name}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div>
                  <h5>Select Tags</h5>
                  <Select
                    mode="multiple"
                    placeholder="Select tags"
                    style={{ width: 200 }}
                    value={selectedTags}
                    onChange={handleTagChange}
                  >
                    {tags.map((tag) => (
                      <Select.Option key={tag.name} value={tag.name}>
                        {tag.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div>
                <h5>Meta Title</h5>
                <Input
                  placeholder="Meta Title"
                  value={metaTitle}
                  onChange={(e) => setmetatitle(e.target.value)}
                />
              </div>

              <div>
                <h5>Meta Description</h5>
                <Input
                  placeholder="Meta Description"
                  value={metaDescription}
                  onChange={(e) => setmetadescription(e.target.value)}
                />
              </div>

              <div>
                <h5>Meta Image</h5>
                <AWSS3Uploader onUploadComplete={handleUploadComplete} />
              </div>

              <h5>Description</h5>

              <CKEditor
                config={{
                  extraPlugins: [uploadPlugin],
                }}
                editor={ClassicEditor}
                data={aboutCompanyDescription}
                onChange={(event, editor) =>
                  setAboutCompanyDescription(editor.getData())
                }
              />

              <h5>Highlights</h5>

              {textFields.map((value, index) => (
                <div key={index} style={{ display: "flex", marginBottom: 8 }}>
                  <Input
                    value={value}
                    onChange={(e) =>
                      handleTextFieldChange(index, e.target.value)
                    }
                  />
                  <Button
                    onClick={() => handleRemoveField(index)}
                    type="primary"
                    danger
                  >
                    Remove
                  </Button>
                </div>
              ))}
              <Button onClick={handleAddMore} style={{ marginTop: "10px" }}>
                Add More
              </Button>

              <Input
                placeholder="Video Link URL"
                value={videoLink}
                onChange={(e) => setVideoLink(e.target.value)}
              />
            </Space>
          </div>

          <div style={{ marginTop: "50px" }}>
            <Breadcrumbs title="Deep Dive" breadcrumbItem="Projects" />
            {/* <h2>Projects</h2> */}

            {projects.map((project) => (
              <div
                key={project.id}
                style={{
                  marginTop: "20px",
                  background: "#ded4d4",
                  padding: "10px",
                }}
              >
                <Space direction="vertical" size={16}>
                  <label
                    htmlFor={`logo-image-field-${project.id}`}
                    className="form-label"
                  >
                    Photo
                  </label>

                  <AWSS3Uploaderdynamic
                    entityId={project.id}
                    entityType="project"
                    onImageUpload={handleImageUpload}
                  />

                  <label
                    htmlFor={`description-field-${project.id}`}
                    className="form-label"
                  >
                    Project Description
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={project.description}
                    onChange={(event, editor) =>
                      handleDescriptionChange(editor.getData(), project.id)
                    }
                  />

                  <label
                    htmlFor={`learn-more-link-field-${project.id}`}
                    className="form-label"
                  >
                    Learn More Link
                  </label>
                  <Input
                    id={`learn-more-link-field-${project.id}`}
                    placeholder="Learn More Link"
                    value={project.learnMoreLink}
                    onChange={(e) => handleLearnMoreLinkChange(e, project.id)}
                  />

                  <Button onClick={() => handleRemove(project.id)}>
                    Remove
                  </Button>
                </Space>
              </div>
            ))}

            <Button
              onClick={handleAddMoreProjects}
              style={{ marginTop: "10px" }}
            >
              Add More
            </Button>
          </div>

          <div style={{ marginTop: "50px" }}>
            <Breadcrumbs title="Deep Dive" breadcrumbItem="Products" />
            {products.map((product) => (
              <div
                key={product.id}
                style={{
                  marginTop: "20px",
                  background: "#ded4d4",
                  padding: "10px",
                }}
              >
                <Space direction="vertical" size={16}>
                  <label
                    htmlFor={`logo-image-field-${product.id}`}
                    className="form-label"
                  >
                    Photo
                  </label>
                  <AWSS3Uploaderdynamic
                    entityId={product.id}
                    entityType="product"
                    onImageUpload={handleImageUpload}
                  />

                  <label
                    htmlFor={`description-field-${product.id}`}
                    className="form-label"
                  >
                    Product Description
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={product.description}
                    onChange={(event, editor) =>
                      handleProductDescriptionChange(
                        editor.getData(),
                        product.id
                      )
                    }
                  />

                  <label
                    htmlFor={`learn-more-link-field-${product.id}`}
                    className="form-label"
                  >
                    Learn More Link
                  </label>
                  <Input
                    id={`learn-more-link-field-${product.id}`}
                    placeholder="Learn More Link"
                    value={product.learnMoreLink}
                    onChange={(e) =>
                      handleProductLearnMoreLinkChange(e, product.id)
                    }
                  />

                  <Button onClick={() => handleRemoveProduct(product.id)}>
                    Remove
                  </Button>
                </Space>
              </div>
            ))}
            <Button
              onClick={handleAddMoreProducts}
              style={{ marginTop: "10px" }}
            >
              Add More
            </Button>
          </div>

          <div style={{ marginTop: "50px" }}>
            <Breadcrumbs title="Deep Dive" breadcrumbItem="Key Milestones" />
            {/* <h2>Key Milestones</h2> */}

            {milestones.map((milestone) => (
              <div
                key={milestone.id}
                style={{
                  marginTop: "20px",
                  background: "#ded4d4",
                  padding: "10px",
                }}
              >
                <Space direction="vertical" size={16}>
                  <label
                    htmlFor={`heading-field-${milestone.id}`}
                    className="form-label"
                  >
                    Heading
                  </label>
                  <Input
                    id={`heading-field-${milestone.id}`}
                    placeholder="Heading"
                    value={milestone.heading}
                    onChange={(e) => handleHeadingChange(e, milestone.id)}
                  />

                  <label
                    htmlFor={`quarters-field-${milestone.id}`}
                    className="form-label"
                  >
                    Quarters
                  </label>
                  <Input
                    id={`quarters-field-${milestone.id}`}
                    placeholder="Quarters"
                    value={milestone.quarters}
                    onChange={(e) => handleQuartersChange(e, milestone.id)}
                  />

                  <label
                    htmlFor={`milestone-field-${milestone.id}`}
                    className="form-label"
                  >
                    Milestone
                  </label>
                  <Input
                    id={`milestone-field-${milestone.id}`}
                    placeholder="Milestone"
                    value={milestone.milestone}
                    onChange={(e) => handleMilestoneChange(e, milestone.id)}
                  />

                  <Button onClick={() => handleRemovemile(milestone.id)}>
                    Remove
                  </Button>
                </Space>
              </div>
            ))}

            <Button onClick={handleAddMoremile} style={{ marginTop: "10px" }}>
              Add More
            </Button>
          </div>

          <div style={{ marginTop: "50px" }}>
            <Breadcrumbs title="Deep Dive" breadcrumbItem="People" />
            {/* <h2>People</h2> */}

            {people.map((person) => (
              <div
                key={person.id}
                style={{
                  marginTop: "20px",
                  background: "#ded4d4",
                  padding: "10px",
                }}
              >
                <Space direction="vertical" size={16}>
                  <label
                    htmlFor={`name-field-${person.id}`}
                    className="form-label"
                  >
                    Name
                  </label>
                  <Input
                    id={`name-field-${person.id}`}
                    placeholder="Name"
                    value={person.name}
                    onChange={(e) => handleNameChange(e, person.id)}
                  />

                  <label
                    htmlFor={`company-field-${person.id}`}
                    className="form-label"
                  >
                    Company
                  </label>
                  <Input
                    id={`company-field-${person.id}`}
                    placeholder="Company"
                    value={person.company}
                    onChange={(e) => handleCompanyChange(e, person.id)}
                  />

                  <label
                    htmlFor={`image-field-${person.id}`}
                    className="form-label"
                  >
                    Image
                  </label>
                  <AWSS3Uploaderdynamic
                    entityId={person.id}
                    entityType="person"
                    onImageUpload={handleImageUpload}
                  />

                  <label
                    htmlFor={`description-field-${person.id}`}
                    className="form-label"
                  >
                    Description
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={person.description}
                    onChange={(event, editor) =>
                      handleDescriptionChangepeople(editor.getData(), person.id)
                    }
                  />

                  <Button onClick={() => handleRemovepeople(person.id)}>
                    Remove
                  </Button>
                </Space>
              </div>
            ))}

            <Button onClick={handleAddMorepeople} style={{ marginTop: "10px" }}>
              Add More
            </Button>
          </div>

          <div style={{ marginTop: "50px" }}>
            <Breadcrumbs title="Deep Dive" breadcrumbItem="Investors" />
            {/* <h2>Investors</h2> */}

            {investors.map((investor) => (
              <div
                key={investor.id}
                style={{
                  marginTop: "20px",
                  background: "#ded4d4",
                  padding: "10px",
                }}
              >
                <Space direction="vertical" size={16}>
                  <label
                    htmlFor={`heading-field-${investor.id}`}
                    className="form-label"
                  >
                    Heading
                  </label>
                  <Input
                    id={`heading-field-${investor.id}`}
                    placeholder="Heading"
                    value={investor.heading}
                    onChange={(e) => handleHeadingChangeInv(e, investor.id)}
                  />

                  <label
                    htmlFor={`month-year-field-${investor.id}`}
                    className="form-label"
                  >
                    Month/Year
                  </label>
                  <Input
                    id={`month-year-field-${investor.id}`}
                    placeholder="Month/Year"
                    value={investor.monthYear}
                    onChange={(e) => handleMonthYearChange(e, investor.id)}
                  />

                  <label
                    htmlFor={`raised-field-${investor.id}`}
                    className="form-label"
                  >
                    Raised
                  </label>
                  <Input
                    id={`raised-field-${investor.id}`}
                    placeholder="Raised"
                    value={investor.raised}
                    onChange={(e) => handleRaisedChange(e, investor.id)}
                  />

                  <label
                    htmlFor={`offer-price-field-${investor.id}`}
                    className="form-label"
                  >
                    Offer Price
                  </label>
                  <Input
                    id={`offer-price-field-${investor.id}`}
                    placeholder="Offer Price"
                    value={investor.offerPrice}
                    onChange={(e) => handleOfferPriceChange(e, investor.id)}
                  />

                  <label
                    htmlFor={`notes-field-${investor.id}`}
                    className="form-label"
                  >
                    Notes
                  </label>
                  <Input
                    id={`notes-field-${investor.id}`}
                    placeholder="Notes"
                    value={investor.notes}
                    onChange={(e) => handleNotesChange(e, investor.id)}
                  />

                  <label
                    htmlFor={`description-field-${investor.id}`}
                    className="form-label"
                  >
                    Description
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={investor.description}
                    onChange={(event, editor) =>
                      handleDescriptionChangeInv(editor.getData(), investor.id)
                    }
                  />

                  <Button onClick={() => handleRemoveInv(investor.id)}>
                    Remove
                  </Button>
                </Space>
              </div>
            ))}

            <Button onClick={handleAddMoreInv} style={{ marginTop: "10px" }}>
              Add More
            </Button>
          </div>

          <div style={{ marginTop: "20px" }}>
            <h5>Company Announcement</h5>
            <Input
              placeholder="Enter Company Announcement Link"
              value={AnnouncementLink}
              onChange={(e) => setAnnouncementlink(e.target.value)}
            />
          </div>

          <div style={{ marginTop: "30px" }}>
            <Button
              onClick={handleSubmit}
              type="primary"
              style={{ marginTop: "20px" }}
            >
              Submit
            </Button>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Companies;
