import React, { useEffect, useState } from "react";
import { Col, Card, CardBody, Row } from "reactstrap";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Spin } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AWSS3Uploader from "../../components/Common/new/s3uploader";
import { addBanner, getBanner, deleteBanner, updateBanner } from "../../requests"; // Include updateBanner request
import { notification } from "antd";

const Banner = () => {
  const [uploadedImages, setUploadedImages] = useState("");
  const [bannerLink, setBannerLink] = useState(""); 
  const [position, setPosition] = useState(""); 
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [modal_list, setModalList] = useState(false);
  const [editMode, setEditMode] = useState(false); // Track if editing
  const [editBannerId, setEditBannerId] = useState(null); // Track which banner is being edited

  function tog_list() {
    setModalList(!modal_list);
  }

  const handleUploadComplete = (uploadedUrl) => {
    setUploadedImages(uploadedUrl);
  };

  const handleRemoveCategory = async (categoryId) => {
    try {
      await deleteBanner(categoryId);
      notification.success({ message: "Removed" });
      getAllBanners();
    } catch (err) {
      notification.error({ message: "Failed" });
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const bannerData = {
        imageUrl: uploadedImages,
        link: bannerLink,
        position: position,
      };

      if (editMode) {
        // Update existing banner
        await updateBanner(editBannerId, bannerData);
        notification.success({ message: "Banner updated successfully!" });
      } else {
        // Add new banner
        await addBanner(bannerData);
        notification.success({ message: "Banner added successfully!" });
      }

      setModalList(false);
      getAllBanners();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  const getAllBanners = async () => {
    try {
      setLoading(true);
      const resp = await getBanner();
      setBanners(resp);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (banner) => {
    setEditMode(true);
    setEditBannerId(banner._id);
    setUploadedImages(banner.imageUrl);
    setBannerLink(banner.link);
    setPosition(banner.position);
    setModalList(true);
  };

  const resetForm = () => {
    setUploadedImages("");
    setBannerLink("");
    setPosition("");
    setEditMode(false);
    setEditBannerId(null);
  };

  useEffect(() => {
    getAllBanners();
  }, []);

  return (
    <div className="page-content">
      <Breadcrumbs title="Skrill Network" breadcrumbItem="Banners" />
      <Spin spinning={loading} fullscreen />
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div id="customerList">
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div className="d-flex gap-1">
                        <Button
                          color="success"
                          className="add-btn"
                          onClick={() => {
                            resetForm(); // Reset form before adding
                            tog_list();
                          }}
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive table-card mt-3 mb-1">
                    <table className="table align-middle table-nowrap">
                      <thead className="table-light">
                        <tr>
                          <th className="sort" data-sort="category">Banner</th>
                          <th className="sort" data-sort="link">Link</th>
                          <th className="sort" data-sort="position">Position</th>
                          <th className="sort" data-sort="actions">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="list form-check-all">
                        {banners.map((item, index) => (
                          <tr key={index}>
                            <td className="category">
                              <img src={item.imageUrl} alt="Banner" style={{ maxWidth: '300px', height: 'auto' }} />
                            </td>
                            <td className="link">
                              <a href={item.link} target="_blank" rel="noopener noreferrer">{item.link}</a>
                            </td>
                            <td className="position">{item.position}</td>
                            <td className="actions">
                              <div className="d-flex gap-2">
                                <button
                                  className="btn btn-sm btn-warning"
                                  onClick={() => handleEdit(item)} // Edit button
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() => handleRemoveCategory(item._id)}
                                >
                                  Remove
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_list();
          }}
        >
          {editMode ? "Edit Banner" : "Add Banner"}
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            <AWSS3Uploader onUploadComplete={handleUploadComplete} />
            <div className="mb-3">
              <label htmlFor="bannerLink" className="form-label">Banner Link</label>
              <input
                type="text"
                className="form-control"
                id="bannerLink"
                placeholder="Enter link"
                value={bannerLink}
                onChange={(e) => setBannerLink(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="bannerPosition" className="form-label">Banner Position</label>
              <input
                type="text"
                className="form-control"
                id="bannerPosition"
                placeholder="Enter position"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setModalList(false)}
              >
                Close
              </button>
              <Button
                disabled={!uploadedImages.length}
                style={{ marginTop: "10px" }}
                type="primary"
                onClick={handleSubmit}
              >
                {editMode ? "Update" : "Submit"}
              </Button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default Banner;
