import React, { useState ,useEffect} from "react";
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Row,Button } from "reactstrap";
import { addads,getADS,deleteADS } from "../../requests";
import {Spin,notification} from "antd";
import AWSS3Uploader from "../../components/Common/new/s3uploader";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Advertisment = () => {
  const [selectedFiles, setselectedFiles] = useState("");
  const [selectedFiles2, setselectedFiles2] = useState("");
  const [selectedFiles3, setselectedFiles3] = useState("");
  const [selectedFiles4, setselectedFiles4] = useState("");
  const[loading,setloading]=useState(false)

const homepagesidebar1=(uploadedUrl)=>{
  setselectedFiles(uploadedUrl)
}  

const homepagesidebar2=(uploadedUrl)=>{
  setselectedFiles2(uploadedUrl)
}

const detailpage=(uploadedUrl)=>{
  setselectedFiles3(uploadedUrl)
}

const detailpage2=(uploadedUrl)=>{
  setselectedFiles4(uploadedUrl)
}

const handleSubmit=async()=>{
  setloading(true)
  try{
    const payload={
      homepage1:selectedFiles,
      homepage2:selectedFiles2,
      detailpage1:selectedFiles3,
      detailpage2:selectedFiles4
    }
    const res=await addads(payload)
    notification.success({
      message: "Submission Successful",
      description: "Your data has been successfully submitted.",
    });
    // console.log(res)

  }catch(err){
    console.log(err)
  }finally{
    setloading(false)
    getAdvertisments()
    notification.error({
      message: "Submission Failed",
      description:
        "There was an error submitting your data. Please try again.",
    });
  }
}

const getAdvertisments=async()=>{
  setloading(true)
  try{
   const res=await getADS()
   console.log(res[0].homepage1)
   setselectedFiles(res[0].homepage1)
   setselectedFiles2(res[0].homepage2)
   setselectedFiles3(res[0].detailpage1)  
   setselectedFiles4(res[0].detailpage2)
  }catch(err){
    console.log(err)
  }finally{
    setloading(false)
  }
}

useEffect(()=>{
  getAdvertisments()
},[])

const handleDelete=async(name)=>{
  setloading(true)
  try{
    const res=await deleteADS(name)
    console.log(res)
    notification.success({
      message: "Deleted",
   
    });

  }catch(err){
    notification.error({
      message: "Something went wrong",
   
    });
    console.log(err)
  }finally{
    setloading(false)
    getAdvertisments()
  }
}

  return (
    <div className="page-content">
      <Spin fullscreen spinning={loading}/>
      <div>
      <Breadcrumbs title="Skrill Network" breadcrumbItem="Advertisments" />
        <Row>
          {/* Row 1: Homepage Sidebar 1 */}
          <Col lg={4}>
            <Card>
              <CardBody>
                <h5>Homepage Sidebar 1</h5>
                <img src={selectedFiles} height={100}/>
                {selectedFiles && <Button color="danger" onClick={() => handleDelete('homepage1')}>Delete</Button>}
                
               <AWSS3Uploader onUploadComplete={homepagesidebar1}/>
              </CardBody>
            </Card>
          </Col>

          {/* Row 2: Homepage Sidebar 2 */}
          <Col lg={4}>
            <Card>
              <CardBody>
                <h5>Homepage Sidebar 2</h5>
                <img src={selectedFiles2} height={100}/>
                {selectedFiles2 && <Button color="danger" onClick={() => handleDelete('homepage2')}>Delete</Button>}
                
               <AWSS3Uploader onUploadComplete={homepagesidebar2}/>
              </CardBody>
            </Card>
          </Col>

          {/* Row 3: Detail Page */}
          <Col lg={4}>
            <Card>
              <CardBody>
                <h5>Detail Page</h5>
                <img src={selectedFiles3} height={100}/>
                {selectedFiles3 && <Button color="danger" onClick={() => handleDelete('detailpage1')}>Delete</Button>}
               
                <AWSS3Uploader onUploadComplete={detailpage}/>
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card>
              <CardBody>
                <h5>Detail Page 2</h5>
                <img src={selectedFiles4} height={100}/>
                {selectedFiles4 && <Button color="danger" onClick={() => handleDelete('detailpage2')}>Delete</Button>}
                
                <AWSS3Uploader onUploadComplete={detailpage2}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Button onClick={handleSubmit} color="primary">Submit</Button>
    </div>
  );
};

export default Advertisment;
