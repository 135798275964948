import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal ,notification,Button} from "antd";
import { useState } from "react";
import { deletedeepDive } from "../../../../requests";
import './companyCard.css';

const CompanyCard = ({ company }) => {
  const navigate = useNavigate();
  const { _id } = company;
  const { companyName } = company?.company || {};

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleEditClick = () => {
    navigate(`/editdeepdive/${_id}`);
  };

  const handleOk = async () => {
    try {
      const token = localStorage.getItem("token");
      const resp = await deletedeepDive(token, _id);
      // console.log(resp);
      notification.success({
        message: "Deleted Successfully",
        description: "Your data has been successfully submitted.",
      });
    } catch (err) {
      notification.error({
        message: "Delete Failed",
        description:
          "There was an error submitting your data. Please try again.",
      });
      console.log(err);
    }
    // Call the delete function here
    console.log(`Deleting ${companyName}`);
    setIsModalVisible(false);
  };

  const handleDeleteClick = () => {
    showModal();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
    <div
     
      className="card-company"
    >
      <h3>{companyName}</h3>
      <div className="button-container">
      <Button type="primary" onClick={handleEditClick}>Edit</Button>
      <Button type="primary" danger onClick={handleDeleteClick}>Delete</Button>
      </div>

      <Modal
        title={`Delete ${companyName}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to delete {companyName}'s Deep Dive?</p>
      </Modal>
    </div>
    </>
  );
};

export default CompanyCard;
